<template>
  <div class="ayd">
    <VueAppbar :fluid="true" :isJustLogo="true" />
    <div class="ayd-content">
      <VueHeadline level="3" weightLevel="3" class="title">{{ item.header }}</VueHeadline>
      <VueText
        class="contract-body"
        weightLevel="2"
        sizeLevel="4"
        color="grey-40"
        v-html="item.text"
      ></VueText>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueAppbar from '@/components/shared/VueAppbar/VueAppbar';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'Ayd',
  components: {
    VueAppbar,
    VueText,
    VueHeadline,
  },
  data() {
    return {
      item: {
        header: 'Gizlilik Bildirimi',
        text: '',
      },
    };
  },
  created() {
    StaticContent.getPublicStaticContent('VeriPolitikalari').then(res => {
      if (res.data.Data) {
        this.item.text = res.data.Data.content;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.ayd {
  height: 100%;
  overflow: auto;
}
.ayd-content {
  padding: 0 9px 30px;
}
.contract-body {
  line-height: 1.71;
}
.title {
  padding-left: 15px;
}
</style>
